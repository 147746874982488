import {createStore} from 'vuex'

const store = createStore({
    namespaced:true,
    state(){
        return{
            "Authorization":{
                Authorization:localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
                UserName:localStorage.getItem('UserName') ? localStorage.getItem('UserName') : '' ,
                SystemRole:localStorage.getItem('SystemRole') ? localStorage.getItem('SystemRole') : '' ,
            }
            //userInfo:localStorage.getItem('UserInfo') ? localStorage.getItem('UserInfo') : ''
        }
    },
    mutations:{
        chageLogin(state,user){
            state.Authorization.Authorization = user.Authorization.aaccessToken
            state.Authorization.UserInfo = user.Authorization.user.realname
            localStorage.setItem('Authorization',user.Authorization.aaccessToken)
            localStorage.setItem('UserName' ,user.Authorization.user.realname)
            localStorage.setItem('SystemRole' ,user.Authorization.user.systemRole)
            // console.log('Authorization : ' ,state.Authorization.Authorization)
            //console.log('User : ' ,state.Authorization.SystemRole)
        } ,
        logout(state){
            // console.log('logout : ' ,state.Authorization)
            state.Authorization.Authorization = '';
            state.Authorization.UserInfo = '';
            localStorage.removeItem('Authorization');
            localStorage.removeItem('UserName');
            localStorage.removeItem('SystemRole');
        }
    }
})
export default store