import axios from 'axios'
import store from '@/store/index'
import router from './router'
import { ElMessage } from 'element-plus';

axios.defaults.baseURL = 'https://service.cdmatrix.cn'
//请求拦截
axios.interceptors.request.use(
    config => {
        if(store.state.Authorization.Authorization != null && store.state.Authorization.Authorization != ''){
            config.headers.Authorization = 'Bearer ' + store.state.Authorization.Authorization
        }
        //console.log('请求拦截器:',store.state.Authorization.SystemRole)
        return config
    } ,
    error => {
        console.log('请求拦截器：错误')
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response => {
        switch(response.data.state){
            case 401:
                router.push('/login')
                break;
            case 403:
                console.log('响应拦截器：权限不足')
                break;
            case 404:
                console.log('响应拦截器：找不到资源')
                break;
            case 500:
                console.log('响应拦截器：服务器错误')
                break;
            case 200:
                console.log('响应拦截器：请求成功')
                break;
        }
        return response
    } ,
    error => {
        console.log('响应拦截器: 错误' ,error.response)
        if(error.response){
            let errorMessage = ''
            switch (error.response.status) {
                case 400:
                    errorMessage = error.response.data.msg
                    //console.log('响应拦截器：',error.response.data.msg)
                    break;
                case 401:
                    //console.log('响应拦截器：未授权')
                    store.commit('logout', { store })
                    router.push('/login')
                    break;
                case 403:
                    errorMessage = '您没有权限访问当前页面'
                    //跳转到未授权页面
                    //console.log('响应拦截器：禁止访问')
                    break;
                case 404:
                    errorMessage = '请求的内容不存在'
                    //console.log('响应拦截器：未找到')
                    break;
                case 500:
                    errorMessage = error.response.data.msg
                    //console.log('响应拦截器：服务器错误')
                    break;
            }
            ElMessage({
                message: errorMessage,
                type: 'warning',
            })
        }else{
            store.commit('logout', { store })
            router.push('/login')
        }
        return Promise.reject(error)
    }
)

export default axios