import router from './index'
import store from '@/store/index'

const whiteList = ['/login']

router.beforeEach((to, from, next) => {
    if(store.state.Authorization.Authorization != null && store.state.Authorization.Authorization != '')
    {
        if (to.path === '/login') {
            next({ path: '/' })
        } else {
            next()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/login')
        }
    }
})