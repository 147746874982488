import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import locale from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import 'normalize.css'
import '@/assets/css/base.css'
import router from './router/index'
import '@/router/permission'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

import axios from './http.js' 
app.config.globalProperties.$axios = axios


app.use(router)
app.use(ElementPlus,{locale})

import {ElMessage} from 'element-plus';
app.config.globalProperties.$message = ElMessage;

import print from 'vue3-print-nb';
app.use(print)

app.mount('#app')
