<template>
    <div class="layout-menu">
        <LayoutMenu :isClose="isClose"></LayoutMenu>
    </div>
    <div class="layout-content" :class="{active:isClose}">
        <LayoutContent :isClose="isClose" @change="change"></LayoutContent>
    </div>
</template>

<script>

    import LayoutMenu from './LayoutMenu';
    import LayoutContent from './LayoutContent';
    import { ref } from 'vue';

    export default {
        components:{
            LayoutMenu,
            LayoutContent
        } ,
        setup(){
            let isClose = ref(false);

            function change(){
                isClose.value = !isClose.value
            }

            return {isClose ,change}
        }
    }
</script>

<style lang="less"  scoped>
    .layout-menu{
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: #112f50;
    }
    .layout-content
    {
        padding-left: 214px;
    }
    .active{
        padding-left:64px;
    }
</style>